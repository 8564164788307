import React from "react";
import ImageZoom from "react-medium-image-zoom";

const screen = require("./assets/screen.png");
const mac = require("./assets/mac.png");
const windows = require("./assets/windows.png");

const baseUrl = "https://image-pdf-desktop-updates.s3.amazonaws.com/";
const appVersion = "1.0.4";

const downloadLinks = [
  {
    icon: mac,
    title: "macOS",
    link: `${baseUrl}imagePDF_mac_${appVersion}.dmg`
  },
  {
    icon: windows,
    title: "Windows",
    link: `${baseUrl}imagePDF_windows_${appVersion}.exe`
  }
];

function NavBar() {
  return (
    <div className="px-10 py-6 bg-gray-100 flex">
      <div className="text-2xl text-gray-800">
        image<span className="font-bold">PDF</span>
        {"   "}
        <span className="text-sm text-gray-500">{appVersion}</span>
      </div>
    </div>
  );
}

function Link({ data }) {
  return (
    <div className="flex flex-col items-center">
      <a
        className="cursor-pointer hover:underline flex md:flex-row items-center justify-center"
        href={data.link}
        target="_blank">
        <img className="w-1/3 md:w-1/4" src={data.icon} alt={data.title} />
        <div className="ml-4">{data.title}</div>
      </a>
    </div>
  );
}

function Home() {
  return (
    <div className="min-h-screen">
      <NavBar />
      <div className="md:flex md:px-10">
        <div className="md:w-3/5">
          <ImageZoom
            image={{
              src: screen,
              alt: "screen"
            }}
            zoomImage={{
              src: screen,
              alt: "screen"
            }}
          />
        </div>
        <div className="p-10 md:p-0 md:w-2/5 md:mt-20">
          <p className="text-4xl font-bold text-gray-700 leading-normal">
            Free, Simple, Easy to use Image to PDF tool.
          </p>
          <div className="flex justify-around mt-10">
            {downloadLinks.map(it => (
              <Link key={it.title} data={it} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
