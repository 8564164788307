import { createStore } from "easy-peasy";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { app } from "./app";

const model = {
  app
};

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["app"]
};

export function initializeStore() {
  return createStore(model, {
    reducerEnhancer: reducer => persistReducer(persistConfig, reducer)
  });
}
