import React from "react";
import { StoreProvider } from "easy-peasy";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { initializeStore } from "../store";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.store = initializeStore();
    this.persistor = persistStore(this.store);
  }

  render() {
    return (
      <StoreProvider store={this.store}>
        <PersistGate loading={<div>loading...</div>} persistor={this.persistor}>
          {this.props.children}
        </PersistGate>
      </StoreProvider>
    );
  }
}

export default App;
